import React, { ReactElement } from 'react'
import { graphql, PageProps } from 'gatsby'
import { Episode } from '../components/episode'
import { AdjacentPodcast } from '../components/player'

export interface PodcastContext {
  digits: number,
  guid: string,
  next: AdjacentPodcast,
  previous: AdjacentPodcast,
}

const EpisodeTemplate = (props: PageProps<GatsbyTypes.PodcastByGuidQuery, PodcastContext, unknown>): ReactElement => {
  return <Episode {...props} />
}

export default EpisodeTemplate
export { EpisodeTemplate }

export const PodcastQuery = graphql`
  query PodcastByGuid($guid: String!) {
    feedPodcast(guid: { eq: $guid }) {
      content {
				encoded
      }
      enclosure {
        length
        type
        url
      }
      formatDate: isoDate(formatString: "dddd, MMMM Do, YYYY")
      guid
      id
      isoDate
      itunes {
        duration
        episode
        image {
          attrs {
            href
          }
        }
      }
      link
      pubDate
      title
    }
  }
`