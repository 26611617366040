import React, { ReactElement, useEffect } from "react"
import { Helmet } from "react-helmet"
import { PodcastContext } from "../templates/episode"
import { formatDuration, formatEp, guessEp, parseDuration, selectGuestSocial, selectTitle } from "../utilities"
import dayjs from "dayjs"
import relativeTime from "dayjs/plugin/relativeTime"
import { PageProps } from "gatsby"
import { PlayerProps } from "./player"

dayjs.extend(relativeTime)

const Episode = (props: PageProps<GatsbyTypes.PodcastByGuidQuery, PodcastContext, any> & { setEpisode?: React.Dispatch<React.SetStateAction<PlayerProps>> } & { JSDOM?: any }): ReactElement => {
  const episode = props?.data?.feedPodcast,
    digits = props?.pageContext.digits,
    day = dayjs(episode?.isoDate),
    ep = formatEp(guessEp(episode), digits),
    title = selectTitle(episode?.title ?? ``),
    // 04/06/23 .... uh... no!
    // 03/28/23 Feed duration now differs from episode duration by consistent 45s
    // duration = formatDuration(parseInt(episode?.itunes?.duration ?? `45`, 10) - 45),
    duration = formatDuration(parseInt(episode?.itunes?.duration ?? `0`, 10)),
    guestSocial = selectGuestSocial(episode?.content?.encoded ?? ``),
    episodeDescriptionHTML = (episode?.content?.encoded?.split(`</p>`)[0].split(`<p>`)[1]
      ?? episode?.content?.encoded?.split(`
  `)[0]) + guestSocial,
    episodeDescriptionText = ((): string => {
      const doc = typeof DOMParser === `undefined`
        ? new props.JSDOM(episodeDescriptionHTML)?.window?.document
        : new DOMParser().parseFromString(episodeDescriptionHTML, `text/html`)
      return doc?.body?.textContent ?? ``
    })().replace(/&nbsp;/g, ` `).replace(/\s+/g, ` `)

  // Update player state when episode mounts. This sets the player state on initial page
  // load and augments existing data with previous/next action information on navigate.
  useEffect(() => {
    if(!props.setEpisode) return

    props.setEpisode({
      autoplay: props.location?.state?.autoplay ?? false,
      duration: parseDuration(duration),
      next: props?.pageContext?.next,
      previous: props?.pageContext?.previous,
      src: episode?.enclosure?.url ?? ``,
      title: `Ep. ${ep} ${title}`,
      type: episode?.enclosure?.type ?? ``,
    })
  }, [])

  return (
    <>
      {props.location.pathname !== `/` && (
        <Helmet>
          {/* TODO: Update with final URL */}
          <link rel="canonical" href={`https://howlonggone.com${props.location.pathname}`} />
          <meta property="og:url" content={`https://howlonggone.com${props.location.pathname}`} />

          <title>Ep. {ep} {title}</title>
          <meta property="og:title" content={`Ep. ${ep} ${title} | How Long Gone`} />
          <meta name="twitter:title" content={`Ep. ${ep} ${title} | How Long Gone`} />

          <meta name="description" content={episodeDescriptionText} />
          <meta property="og:description" content={episodeDescriptionText} />
          <meta name="twitter:description" content={episodeDescriptionText} />

          <meta property="og:audio" content={episode?.enclosure?.url} />
          <meta property="og:audio:type" content={episode?.enclosure?.type} />
        </Helmet>
      )}
      <div className="mb-2">
        <h1 className="text-4xl mb-2">
          <span className="sentence font-bold">{day.fromNow()}</span> ep. {ep} feat. <span className="font-bold">{title}</span> {duration}
        </h1>
        <p className="text-xs children-font-normal lg:line-clamp-3" dangerouslySetInnerHTML={{ __html: episodeDescriptionHTML }} />
      </div>
    </>
  )
}

export default Episode
export { Episode }